const brandColors = {
  primary: '#0B3D5B',
  secondary: '#21FFB2', //'#23FEB2',
};

const sidebarColors = {
  backgroundColor: '#EBEBEB',
  color: '#0B3D5B',
  dotfileLogo: '#0B3D5B',
};

const linkColors = {
  color: '	#007FFF',
};

export const tokens = {
  brand: brandColors,
  sidebar: sidebarColors,
  link: linkColors,
};
